import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import CalendarViewDataContainer from '../Widgets/CalendarView/CalendarViewDataContainer';
import {
  getActiveMarker,
  getCurrentLocation,
  getSelectedHours,
  getSelectedStartDate,
  getSelectedEndDate,
  getSensorsByVarName,
  getSensorsByLocId,
} from '../state/selectors';
import {
  SensorValueItem,
  CalendarSelectionType,
  getAvgValue,
  getHourSet,
  HoveredPlot,
  processRawData,
} from '../Widgets/CalendarView/helpers';
import MapViewWrapper from '../Widgets/HelperComponents/MapViewWrapper';
import { DailyMetricItem } from '../services/api';
import CalendarTimeSelection from '../Widgets/CalendarView/CalendarTimeSelection';
import DataStore from '../services/dataStore';

dayjs.extend(weekOfYear);
dayjs.extend(localizedFormat);

function CalendarView(): JSX.Element {
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const selectedHours = useSelector(getSelectedHours);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const dataStore = DataStore.getInstance();

  const sensorIdsInLoc = useSelector(getSensorsByLocId).get(currentLocation) || [];
  const sensorIdsWithVar = useSelector(getSensorsByVarName).get(activeMarker) || [];
  const activeSensorIds = sensorIdsInLoc.filter((id) => sensorIdsWithVar.includes(id));

  const [loading, setLoading] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState<boolean>(false);
  const [locDailyData, setlocDailyData] = useState<DailyMetricItem[]>([]);
  const [hoveredPlot, setHoveredPlot] = useState<HoveredPlot>();
  const [selectionType, setSelectionType] = useState<CalendarSelectionType>(
    CalendarSelectionType.week
  );

  // fetch the data from api and update data store
  useEffect(() => {
    setlocDailyData([]);
    setLoading(true);
    dataStore
      .getLocDailyMetricHistory(
        currentLocation,
        activeMarker,
        new Date(startDate).getTime(),
        new Date(endDate).getTime()
      )
      .then((history) => {
        if (history) {
          // Limit only to selectedHours (update max/min/tot)
          const { selectHours, startHour, endHour } = selectedHours;
          const allHours = Array.from(new Set(getHourSet(selectHours, startHour, endHour)));
          if (allHours.length === 24) setlocDailyData(history);
          else setlocDailyData(processRawData(history, allHours));
          setLoading(false);
        }
      })
      .catch(() => {
        setlocDailyData([]);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker, currentLocation, startDate, endDate, selectedHours, activeSensorIds.length]);

  // create an array for sensors in loc with its summary values
  const sensorSummaryValues = useMemo(() => {
    const sensorAverages: SensorValueItem[] = [];
    for (let i = 0; i < activeSensorIds.length; i++) {
      const avgValue = getAvgValue(
        locDailyData,
        activeSensorIds[i],
        activeMarker,
        selectedHours,
        hoveredPlot
      );
      if (avgValue !== undefined) {
        const hasValue = sensorAverages.some((data) => data.id === activeSensorIds[i]);
        if (!hasValue) sensorAverages.push({ id: activeSensorIds[i], avg: avgValue });
        else {
          const sensorData = sensorAverages.find((data) => data.id === activeSensorIds[i]);
          if (sensorData && sensorData.avg !== avgValue) {
            const valuePos = sensorAverages.indexOf(sensorData);
            sensorAverages[valuePos].avg = avgValue;
          }
        }
      }
    }
    return sensorAverages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locDailyData, selectedHours, hoveredPlot, activeMarker]);

  return (
    <MapViewWrapper sensorSummaryValues={sensorSummaryValues}>
      <Box sx={{ paddingLeft: '10px' }}>
        <CalendarTimeSelection
          selectionType={selectionType}
          setSelectionType={setSelectionType}
          setDownloadData={setDownloadData}
        />
        <CalendarViewDataContainer
          sensorSummaryValues={sensorSummaryValues}
          locDailyData={locDailyData}
          hoveredPlot={hoveredPlot}
          setHoveredPlot={setHoveredPlot}
          selectionType={selectionType}
          downloadData={downloadData}
          setDownloadData={setDownloadData}
          loading={loading}
        />
      </Box>
    </MapViewWrapper>
  );
}

export default CalendarView;
