import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import StackedHistoryPlot from '../Plots/StackedHistoryPlot';
import CompareReport from './CompareReport';
import { getUnselectedTimeRange, SensorHistoryPlotItem, TimeRange } from '../Plots/plotCommon';
import LoaderWithBackdrop from '../HelperComponents/LoaderWithBackdrop';
import chartColors from '../../utils/chartColors';

import {
  getActivePlotVars,
  getGhostParams,
  getSelectedEndDate,
  getSelectedHours,
  getSelectedSensorIds,
  getSelectedStartDate,
  getSensorsById,
  getVarnameRefreshTamp,
} from '../../state/selectors';
import { isDataExpired } from '../../utils/functions';
import { SensorLatest } from '../../services/api';
import DataStore from '../../services/dataStore';

export function SensorDataPlotWrapper(): JSX.Element {
  const selectedSensorIds = useSelector(getSelectedSensorIds);
  const sensorDetails = useSelector(getSensorsById);
  const selectedVarNames = useSelector(getActivePlotVars);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const selectedHours = useSelector(getSelectedHours);
  const { selectHours } = selectedHours;

  const ghostParams = useSelector(getGhostParams);
  const { addGhosts, weekCount } = ghostParams;

  // To help refresh data once loaded into store
  const refreshToken = useSelector(getVarnameRefreshTamp);

  const [sensorData, setSensorData] = useState<SensorHistoryPlotItem[]>([]);
  const [fetchingSensorData, setfetchingSensorData] = useState(false);

  const selectedTimeRange: TimeRange = useMemo(
    () => ({
      start: new Date(startDate).getTime(),
      end: new Date(endDate).getTime(),
    }),
    [startDate, endDate]
  );

  // Fetch the data we need for the graph and report
  useEffect(() => {
    const selectedSensorDetails: SensorLatest[] = [];
    selectedVarNames.forEach((varName) => {
      selectedSensorIds.forEach((id) => {
        const sensor = sensorDetails.get(id);
        // Only attempt to fetch data if this sensor has this varName
        const hasVarName = sensor?.data?.map((datum) => datum.varName).includes(varName);
        const varNameRefreshStamp = refreshToken.get(varName);
        // only add if it isn't already there
        if (sensor && selectedSensorDetails.findIndex((s) => s.id === id) === -1) {
          if (hasVarName) {
            selectedSensorDetails.push(sensor);
          } else if (varNameRefreshStamp && !isDataExpired(varNameRefreshStamp, 5))
            selectedSensorDetails.push(sensor);
        }
      });
    });
    setfetchingSensorData(true);
    // Wait till we've got all the details
    if (selectedSensorDetails.length !== selectedSensorIds.length) {
      return;
    }
    // HACK to prevent fetching 6Month/Year data if selected on calendar page
    // DateRangePicker will auto-switch to 1 week but we want to prevent fetching data
    const monthDiff = dayjs(endDate).diff(dayjs(startDate), 'month', true);
    if (monthDiff > 11.9999 && monthDiff <= 12) {
      return;
    }
    if (monthDiff > 5.99 && monthDiff <= 6) {
      return;
    }

    const ghostTimeOffsetVal = weekCount * 7 * 24 * 3600;

    // Request the data from the store
    const dataStore = DataStore.getInstance();
    const newItems: Promise<SensorHistoryPlotItem>[] = [];
    selectedVarNames.forEach((varName) => {
      selectedSensorDetails.forEach((sensor) => {
        // Only attempt to fetch data if this sensor has this varName
        const hasVarName = sensor.data?.map((datum) => datum.varName).includes(varName);
        if (hasVarName) {
          const promisedItem = dataStore
            .getHistory(sensor.id, varName, selectedTimeRange.start, selectedTimeRange.end)
            .then((history) => {
              const dataItem: SensorHistoryPlotItem = {
                varName,
                sensorId: sensor.id,
                sensorName: sensor.name ?? '',
                history: {
                  time: history.map(({ time }) => time),
                  value: history.map(({ value }) => value),
                },
                color: chartColors[selectedSensorIds.indexOf(sensor.id) % chartColors.length],
              };
              return dataItem;
            })
            .catch(() => {
              // If we can't find sensor use empty history
              const dataItem: SensorHistoryPlotItem = {
                varName,
                sensorId: sensor.id,
                sensorName: sensor.name ?? '',
                history: {
                  time: [],
                  value: [],
                },
                color: chartColors[selectedSensorIds.indexOf(sensor.id) % chartColors.length],
              };
              return dataItem;
            });
          newItems.push(promisedItem as Promise<SensorHistoryPlotItem>);
          if (addGhosts) {
            const promisedGhost = dataStore
              .getHistory(
                sensor.id,
                varName,
                selectedTimeRange.start - ghostTimeOffsetVal * 1000,
                selectedTimeRange.end - ghostTimeOffsetVal * 1000,
                true // Force loading data since will already be loading later data
              )
              .then((history) => {
                const dataItem: SensorHistoryPlotItem = {
                  varName,
                  sensorId: `${sensor.id}-GHOST`,
                  sensorName: `${sensor.name}-GHOST`,
                  history: {
                    time: history.map(({ time }) => time + ghostTimeOffsetVal),
                    value: history.map(({ value }) => value),
                  },
                  color: chartColors[selectedSensorIds.indexOf(sensor.id) % chartColors.length],
                  ghost: true,
                };
                return dataItem;
              })
              .catch(() => {
                // If we can't find sensor use empty history
                const dataItem: SensorHistoryPlotItem = {
                  varName,
                  sensorId: `${sensor.id}-GHOST`,
                  sensorName: `${sensor.name}-GHOST`,
                  history: {
                    time: [],
                    value: [],
                  },
                  color: chartColors[selectedSensorIds.indexOf(sensor.id) % chartColors.length],
                  ghost: true,
                };
                return dataItem;
              });
            newItems.push(promisedGhost as Promise<SensorHistoryPlotItem>);
          }
        }
      });
    });

    // Wait for the data and update
    Promise.all(newItems).then((values) => {
      setfetchingSensorData(false);
      setSensorData((s) =>
        s
          .filter(
            (item) =>
              // Remove items that are no longer selected
              selectedSensorIds.includes(item.sensorId) &&
              selectedVarNames.includes(item.varName) &&
              // Remove any duplicate entry for new data
              !values
                .map((n) => `${n.varName},${n.sensorId}`)
                .includes(`${item.varName},${item.sensorId}`)
          )
          .concat(values)
          .sort((a, b) => selectedVarNames.indexOf(a.varName) - selectedVarNames.indexOf(b.varName))
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedVarNames,
    selectedSensorIds,
    selectedTimeRange,
    ghostParams,
    sensorDetails,
    refreshToken,
  ]);

  // create unselected time ranges for the NON selected times to grey them out
  const unselectedTimes = useMemo(() => {
    if (!selectHours) return undefined;
    const hTs = getUnselectedTimeRange(selectedHours, startDate, endDate);
    return hTs;
  }, [selectHours, selectedHours, startDate, endDate]);

  return (
    <div style={{ height: '100%' }}>
      {fetchingSensorData && <LoaderWithBackdrop />}
      <StackedHistoryPlot dataItems={sensorData} unselectedTimes={unselectedTimes} />
      <CompareReport sensorData={sensorData} fetchingSensorData={fetchingSensorData} />
    </div>
  );
}

export default SensorDataPlotWrapper;
